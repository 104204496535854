import { useLazyGetReportBySlugQuery, useUpdateReportMutation } from '@api';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppSelector } from '@hooks';
import { ClientTemplateParams } from '@router';
import { reportValidator } from 'common/validators';

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { useEditorNavigation } from 'src/router/hooks';
import { PathNames } from 'src/router/router';
import { z } from 'zod';

interface ModalCreateReportProps {
    isOpen: boolean;
    onClose: () => void;
}

const createFromTemplateSchema = reportValidator.createFromTemplate.pick({
    title: true,
    slug: true,
    password: true
});
type FormData = z.infer<typeof createFromTemplateSchema>;

const ModalSaveDraftReport: React.FC<ModalCreateReportProps> = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    //const goToEditor = useEditorNavigation();
    const params = useParams<ClientTemplateParams>();
    const activeReport = useAppSelector(selectActiveReport);
    const [updateReport] = useUpdateReportMutation();
    const [fetchReport] = useLazyGetReportBySlugQuery();
    const [hasChangedSlugManually, setHasChangedSlugManually] = useState(false);
    const {
        control,
        formState,
        handleSubmit,
        register,
        setError,
        setValue,
        reset,
        watch
    } = useForm<FormData>({
        resolver: zodResolver(createFromTemplateSchema),
        mode: 'onBlur',
        defaultValues: {
            title: '',
            slug: '',
            password: ''
        },
        values: {
            title: activeReport?.title ?? '',
            slug: activeReport?.slug ?? '',
            password: activeReport?.password ?? ''
        }
    });
    const { errors } = formState;

    const slug = watch('slug');

    const onSubmit = handleSubmit(async (data) => {
        try {
            const reportSlug = data.slug;
            await updateReport({
                id: activeReport?.id,
                slug: reportSlug,
                password: data.password,
                title: data.title,
                isDraft: false
            }).unwrap();
            toast.success('Report saved successfully.');
            try {
                fetchReport({ slug: reportSlug, clientId: +params.clientId! });
            } catch (e) {
                toast.error('An error occurred while fetching the report.');
            }
            //goToEditor(params.clientId!, reportSlug, params.viewSlug);
            navigate(PathNames.DASHBOARD_REPORTS);
            onClose();
        } catch (e: any) {
            if ('status' in e && e.status === 409) {
                setError('slug', {
                    type: 'manual',
                    message: 'Client already has report with this slug.'
                });
                return;
            }
            toast.error('An error occurred while saving the report.');
            console.error(e);
        }
    });

    const inValid = Object.keys(errors).length > 0;

    const handleClose = () => {
        reset();
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="2xl" isCentered>
            <ModalOverlay backdropFilter="blur(10px)" />
            <ModalContent border="none">
                <ModalHeader>Save report</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb="2rem">
                    <SimpleGrid columns={2} gap="1.5rem">
                        <GridItem>
                            <FormControl isRequired isInvalid={Boolean(errors.title)}>
                                <FormLabel>Report title</FormLabel>
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            placeholder="Report title"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                const value = e.target.value
                                                    ? e.target.value
                                                          .replace(/ /g, '-')
                                                          .toLowerCase()
                                                    : '';

                                                if (!hasChangedSlugManually || !slug) {
                                                    setValue('slug', value);
                                                }
                                            }}
                                        />
                                    )}
                                    name="title"
                                />
                                <FormErrorMessage>
                                    {errors.title?.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isRequired isInvalid={Boolean(errors.slug)}>
                                <FormLabel>Slug</FormLabel>
                                <Controller
                                    control={control}
                                    name="slug"
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            placeholder="report-slug"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setHasChangedSlugManually(true);
                                            }}
                                        />
                                    )}
                                />

                                <FormErrorMessage>
                                    {errors.slug?.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem>
                            <FormControl isRequired isInvalid={Boolean(errors.password)}>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="text"
                                    placeholder="********"
                                    {...register('password')}
                                />
                                <FormErrorMessage>
                                    {errors.password?.message?.toString()}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="solid"
                        isDisabled={inValid}
                        onClick={() => onSubmit()}
                    >
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalSaveDraftReport;
