import { useCreateFromTemplateMutation } from '@api';
import { Button, Divider, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { ClientIdParams, ReportSlugParams } from '@router';
import { HappyModal } from '@tasklogy/zircon-ui-components';
import { DataSourceIdentifier } from 'common/enums';
import { ReportPage } from 'common/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {  useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClientConnectedSource } from 'src/redux/types/api';
import { useEditorNavigation } from 'src/router/hooks';
import TemplateDataSources from './TemplateDataSources/TemplateDataSources';

export interface HeaderProps {
    pages: ReportPage[];
    clientConnectedSources: ClientConnectedSource[];
}

const Header: React.FC<HeaderProps> = ({ pages, clientConnectedSources }) => {
    const goToEditor = useEditorNavigation();
    const {
        isOpen: isOpenWarning,
        onOpen: onOpenWarning,
        onClose: onCloseWarning
    } = useDisclosure();
    const [createFromTemplate] = useCreateFromTemplateMutation();
    const params = useParams<ClientIdParams & ReportSlugParams>();
    const pageDataSourcesFormMethods = useFormContext();
    const { watch, getValues, formState } = pageDataSourcesFormMethods;
    const { errors } = formState;
    const formPages = watch('pages');
    const allPagesDataSources = Array.from(
        new Set(pages.map((page) => page.usedSources).flat())
    );
    const selectedPages = pages.filter((page) => formPages.includes(page.id));
    const unionDataSources = Array.from(
        new Set(selectedPages.map((page) => page.usedSources).flat())
    );

    const dataSources = allPagesDataSources?.map((dataSource: DataSourceIdentifier) => {
        const isSelected = unionDataSources.includes(dataSource);
        return {
            dataSource,
            isSelected
        };
    });

    const handleOpen = async () => {
        const selectedConnectedSources = getValues('connectedSources');
        const connectedSourcesLength = Object.keys(selectedConnectedSources).length;

        try {
            if (unionDataSources.length === connectedSourcesLength) {
                await handleCreateReport();
            } else {
                onOpenWarning();
            }
        } catch (error) {
            toast.error('An error occurred while creating the report.');
        }
    };

    const handleCreateReport = async () => {
        try {
            const values = getValues();
            const response = await createFromTemplate({
                slug: 'draft',
                password: null,
                title: `Draft - ${params.reportSlug}`,
                clientId: values.clientId,
                templateId: values.templateId,
                connectedSources: values.connectedSources,
                pages: values.pages,
                isDraft: true
            }).unwrap();
            toast.success('Report created successfully.');
            goToEditor(params.clientId!, response.slug);
        } catch (error) {
            toast.error('An error occurred while creating the report.');
        }
    };
    return (
        <Flex w="100%" h="70px" justifyContent="space-between" alignItems="top" pr="2rem">
            <Button onClick={() => handleCreateReport()} variant="outline">
                Edit template
            </Button>
            <Flex>
                <TemplateDataSources
                    dataSources={dataSources}
                    clientConnectedSources={clientConnectedSources}
                />
                <Divider orientation="vertical" mx="1rem" />
                <Button
                    isDisabled={Boolean(errors.pages?.message)}
                    variant="solid"
                    onClick={() => handleOpen()}
                >
                    Continue
                </Button>
            </Flex>
            <HappyModal isOpen={isOpenWarning} onClose={onCloseWarning}>
                <HappyModal.Body>
                    <Text>
                        You did not connect all data sources that are used in the selected
                        pages.
                        <br /> Do you want to continue?
                    </Text>
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={onCloseWarning}>
                        Close
                    </Button>
                    <Button variant="solid" onClick={() => handleCreateReport()}>
                        Continue
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </Flex>
    );
};

export default Header;
